import React from 'react';
import { graphql } from 'gatsby';
import Path from '../services/path';
import { prepareAllStationData } from '../services/station-helper';
import Layout from '../components/layout/layout';
import SEO from '../components/seo';
import CardGroup from '@bfbs/storybook/dist/components/organisms/card-group/card-group';
import SlimlineBannerBlock from '@bfbs/storybook/dist/components/molecules/slimline-banner-block/slimline-banner-block';

const Stations = ({ data }) => {
  const breadcrumbs = [
    { name: 'Radio', path: Path.basePath },
    { name: 'All stations', path: null },
  ];

  return (
    <Layout breadcrumbs={breadcrumbs}>
      <SEO title="Stations" />

      <SlimlineBannerBlock background="background--tertiary-two" heading="All stations" icon="radio-white" />

      <CardGroup
        isSlider={false}
        cards={prepareAllStationData(data)}
        customClasses="row--padding-y o-card-group--rounded-img-padding o-card-group--rounded"
      />
    </Layout>
  );
};

export default Stations;

export const stationsQuery = graphql`
  query Stations {
    allStation(filter: {metadatastation: {weight: {ne: 0}}}) {
      edges {
        node {
          title
          metadatastation {
            logo128x128
            svg1by1
            weight
          }
        }
      }
    }
  }
`;
